.barchart-tooltip {
    background: #555;
    min-width: 200px;
    min-height: 100px;
    text-align: center;
    opacity: 0.6;
    color: #FFF;
    padding-top: 20px;

    & > div{
        margin-top: 2px;
    }
}

.apexcharts-tooltip {
    border-radius: 0!important;
    border-color: transparent;
}


.id-table-wrapper {
    border: 1px solid #e0e0e0;
}

.submission-id-table {
    background: #fff;
    width: 100%;
    border-collapse: collapse;
    margin-top: 6px;
    font-size: 12px;
    overflow: auto;
}